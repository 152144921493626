import React from 'react';

import HTML from 'app/components/HTML';
import Screen from 'app/components/Screen';
import useMetaData from 'app/hooks/useMetaData';
import { RootScreenProps, RootStackParamList } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';

export type TermsConditionsScreenProps = RootScreenProps<RootStackParamList, 'TermsConditions'>;

export default function TermsConditionsScreen({ navigation, route }: TermsConditionsScreenProps) {
  const { t } = useI18n();
  useMetaData('TermsConditions');
  return (
    <Screen testID="TermsConditionsScreen">
      <HTML html={t<string>('gen_terms_conditions_description')} />
    </Screen>
  );
}
