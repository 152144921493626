import { Box, VStack } from 'native-base';
import React from 'react';

import Hero from 'app/components/Hero';
import HTML from 'app/components/HTML';
import Screen from 'app/components/Screen';
import useMetaData from 'app/hooks/useMetaData';
import useProduct from 'app/hooks/useProduct';
import { ProductStackParamList } from 'app/navigation/ProductStackNavigator';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { expandTpl } from 'app/services/I18n';
import { getHero } from 'app/services/ProductHelper';

export type ProductFAQScreenProps = RootScreenProps<ProductStackParamList, 'ProductFAQ'>;

export default function ProductFAQScreen({ navigation, route }: ProductFAQScreenProps) {
  const { productId: productContentKey } = route.params ?? {};
  const { productContent } = useProduct({ productContentKey });
  useMetaData('ProductFAQ', { productName: productContent?.name });

  return (
    <Screen beforeContainer={<Hero url={getHero(productContent).url} />} testID="ProductFAQScreen">
      <VStack space="3">
        {productContent?.faqs?.map(({ question, answer }) => (
          <Box key={question}>
            <HTML
              html={`<strong>${question}</strong> ${expandTpl(answer ?? '', {
                props: {
                  num_days: productContent?.validDays ?? 0,
                  product_id: productContent?.key ?? '',
                },
              })}`}
            />
          </Box>
        ))}
      </VStack>
    </Screen>
  );
}
