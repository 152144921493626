/* eslint-disable camelcase */

import { useEffect } from 'react';
import { Platform } from 'react-native';

import useRootNavigation from 'app/hooks/useRootNavigation';
import { useI18n } from 'app/providers/I18nProvider';
import { TranslationHelper } from 'app/services/I18n';

const MyCityPassBrand = 'CityPASS®';
const MyCityPassSiteTitle = 'my_citypass_app_title';

export type DocumentPage =
  | 'Destinations'
  | 'Product'
  | 'Attraction'
  | 'AttractionTickets'
  | 'ProductFAQ'
  | 'ProductOffers'
  | 'Purchases'
  | 'TermsConditions';

export const DocumentTitleMap: Record<DocumentPage, string> = {
  Destinations: 'my_citypass_destinations_title',
  Product: 'my_citypass_product_title',
  Attraction: 'my_citypass_attraction_title',
  AttractionTickets: 'my_citypass_attraction_ticket_title',
  ProductFAQ: 'my_citypass_faq_title',
  ProductOffers: 'my_citypass_offers_upgrades_title',
  Purchases: 'my_citypass_purchase_title',
  TermsConditions: 'my_citypass_terms_title',
};

interface MetaDataParamProps {
  productName?: string | null;
  attractionName?: string | null;
}

export const createGenericMetaData = (pageName: string | undefined, t: TranslationHelper): string =>
  `${t(MyCityPassSiteTitle, { citypass: MyCityPassBrand })}${pageName ? ` - ${pageName}` : ''}`;

export default function useMetaData(key: DocumentPage, params?: MetaDataParamProps) {
  const { t } = useI18n();
  const navigation = useRootNavigation();

  useEffect(() => {
    if (Platform.OS !== 'web') return;

    const page = t(DocumentTitleMap[key], {
      product_name: params?.productName || '',
      attraction_name: params?.attractionName || '',
      citypass: MyCityPassBrand,
    });

    const title = `${t(MyCityPassSiteTitle, { citypass: MyCityPassBrand })} - ${page}`;
    navigation.setOptions({ customTitle: title } as any);
  }, [key, navigation, params?.attractionName, params?.productName, t]);
}
