import { Box, Center, VStack } from 'native-base';
import React from 'react';

import { InfoRowPartial } from 'app/components/DebugInfo';
import { CitypassTicketLogo } from 'app/components/icons';
import Screen from 'app/components/Screen';
import SecretDevToolsTrigger from 'app/components/SecretDevToolsTrigger';
import useDebugInfo from 'app/hooks/useDebugInfo';
import { RootScreenProps, RootStackParamList } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';

export type AppInfoScreenProps = RootScreenProps<RootStackParamList, 'AppInfo'>;

export default function AppInfoScreen() {
  const {
    appVersion,
    deviceOS,
    sessionId,
    deviceType,
    deviceTypeName,
    platformName,
    manufacturer,
    model,
    nativeAppVersion,
    nativeBuildVersion,
    updateId,
  } = useDebugInfo();
  const { t, locale, locales } = useI18n();
  const localeLabel = locales.find((l) => {
    return l[0] === locale;
  })?.[1].label;

  const colPercent = 30;

  return (
    <Screen testID="AppInfoScreen">
      <VStack alignItems="center" space="4" w="full">
        <Box
          pl={{
            base: '0',
            sm: '2',
            md: '4',
            lg: '6',
          }}
          width={{
            base: 'full',
            sm: '25%',
            md: '50%',
            lg: '70%',
          }}
        >
          <InfoRowPartial
            left={t('app_version')}
            leftColumnPercent={colPercent}
            noBorder
            right={appVersion}
          />
          <InfoRowPartial
            left={t('app_native_version')}
            leftColumnPercent={colPercent}
            noBorder
            right={nativeAppVersion}
          />
          <InfoRowPartial
            left={t('app_native_build_version')}
            leftColumnPercent={colPercent}
            noBorder
            right={nativeBuildVersion}
          />
          <InfoRowPartial
            left={t('app_update_id')}
            leftColumnPercent={colPercent}
            noBorder
            right={updateId}
          />
          <InfoRowPartial
            left={t('app_device_os')}
            leftColumnPercent={colPercent}
            noBorder
            right={deviceOS}
          />
          <InfoRowPartial
            left={t('app_device_platform')}
            leftColumnPercent={colPercent}
            noBorder
            right={platformName}
          />
          <InfoRowPartial
            left={t('app_device_type')}
            leftColumnPercent={colPercent}
            noBorder
            right={deviceTypeName}
          />
          {deviceType.toLowerCase() !== 'desktop' ? (
            <Box>
              <InfoRowPartial
                left={t('app_device_manufacturer')}
                leftColumnPercent={colPercent}
                noBorder
                right={manufacturer}
              />
              <InfoRowPartial
                left={t('app_device_model')}
                leftColumnPercent={colPercent}
                noBorder
                right={model}
              />
            </Box>
          ) : null}
          <InfoRowPartial
            left={t('gen_language')}
            leftColumnPercent={colPercent}
            noBorder
            right={localeLabel}
          />
          <InfoRowPartial
            left={t('app_session')}
            leftColumnPercent={colPercent}
            noBorder
            right={sessionId}
          />
        </Box>
      </VStack>

      <SecretDevToolsTrigger>
        <Center pt={6} role="presentation">
          <CitypassTicketLogo size={260} />
        </Center>
      </SecretDevToolsTrigger>
    </Screen>
  );
}
